
<div class="membersOuter">
    
    <div class="row">
        <div class="col-lg-12" *ngFor="let comment of data; let i = index">
            <div class="memberItem">
              <figure><img *ngIf="comment?.user?.profile_photo_full_path" src="{{comment?.user?.profile_photo_full_path}}" alt="">
              <img *ngIf="!comment?.user?.profile_photo_full_path" src="./assets/images/no_image.jpg" alt=""></figure>

              <div class="memberCont">
                <p>{{comment?.comment}}</p>
                <button type="button" class="handbtn" (click)="edit(i)" *ngIf="USER?.id === comment?.user?.id"><mat-icon>edit</mat-icon></button>
                <div class="commentBox" *ngIf="isEdit === i">
                    <textarea placeholder="comment here............" [(ngModel)]="comments" ></textarea>
                    <button type="submit" class="btn1 text-uppercase" (click)="submit(comment)">Edit
                        Comment</button>
                  </div>
            </div>
            </div>
          </div>
      <!-- <div class="col-lg-6">
        <div class="memberItem">
          <figure><img src="./assets/images/how-we-different-banner.jpg" alt=""></figure>
          <div class="memberCont">
            <p>2959 Hummingbird Way</p>
            <button type="button" class="handbtn"><img src="./assets/images/handic.svg" alt=""></button>
            <a  class="btn">Send <i class="fa fa-sign-in"></i></a>
        </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="memberItem">
          <figure><img src="./assets/images/how-we-different-banner.jpg" alt=""></figure>
          <div class="memberCont">
            <p>2959 Hummingbird Way</p>
            <button type="button" class="handbtn"><img src="./assets/images/handic.svg" alt=""></button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="memberItem">
          <figure><img src="./assets/images/how-we-different-banner.jpg" alt=""></figure>
          <div class="memberCont">
            <p>2959 Hummingbird Way</p>
            <button type="button" class="handbtn disable"><img src="./assets/images/handic.svg" alt=""></button>
          </div>
        </div>
      </div> -->
    </div>
  </div>