import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@envconfig";
import { PaymentComponent } from "@project/modal/payment/payment.component";
import { ApiService } from "@project/services/api.service";
import { EventService } from "@project/services/event.service";
import { StorageService } from "@project/services/storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  host: {
    "(window:click)": "onClick()",
  },
})
export class HeaderComponent implements OnInit {
  USER: any;
  profile: any;
  imgUrl: any;
  show1: boolean;
  length: number = 0;
  show: boolean;
  notificationList: any;
  list: any = [];
  isSearch: boolean;
  user: any;
  isSearchUrl: boolean;
  result: any;
  isShow: boolean = true;
  // Add Class On Body
  toogleBtn(): any {
    document.body.classList.toggle("bodyActive");
  }

  constructor(
    private storage: StorageService,
    private event: EventService,
    private api: ApiService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.imgUrl = environment.IMAGE_BASE_URL;
    this.event.isLogin.subscribe((resp: any) => {
      if (resp) {
        this.USER = this.storage.getUser();
        console.log(this.router.url);

        if (this.router.url === "/search-parent") {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
        this.getProfile();
        this.getNotification();
      } else {
        this.USER = null;
      }
    });

    this.event.isRouterChanged.subscribe((resp: any) => {
      console.log("RESP-->", resp);
      if (resp) {
        console.log(this.router.url);
        if (this.router.url === "/search-parent") {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
        this.getNotification();
      }
    });
  }

  getNotification() {
    this.notificationList = [];
    this.length = 0;
    this.api.get("notifications").subscribe((res: any) => {
      if (res.status) {
        this.notificationList = res.data;
        console.log(this.notificationList);
        // console.log('Sfter reverse--->', this.notificationList.reverse())
        // this.list = this.notificationList.reverse();
        // this.length = this.notificationList.length;

        // console.log('list-->', this.list)
        // this.list = this.notificationList.sort((a: any, b: any) => b.created_at - a.created_at);
        // console.log('list--->', this.list);

        this.notificationList.forEach((ele: any) => {
          if (ele.is_read === 0) {
            this.length++;
          }
          // console.log("length--->" + this.length);
        });
      } else {
        this.notificationList = [];
      }
    });
  }

  getfilterValue() {
    //console.log(ev.target.value);
    // if (ev.target.value === '1' || ev.target.value === '3') {
    //   this.filterList = this.girlsData.sort((a: any, b: any) => b.count - a.count);
    // } else {
    //   this.filterList = this.girlsData.sort((a: any, b: any) => a.count - b.count);
    // }
  }

  markAsRead(item: any) {
    console.log("id--->", item);
    if (item.is_read === 0) {
      let data = {
        notification_id: item.id,
      };
      this.api.post("read-notification", data).subscribe(
        (res: any) => {
          if (res.status) {
            // this.api.alert(res.message, 'success');
            // this.getNotification();
            this.length--;
          } else {
            this.api.alert(res.message, "warning");
          }
        },
        (err) => {
          this.api.alert("Something went wrong", "error");
        }
      );
    }
    if (item.type === "group") {
      this.router.navigate(["/group-details/", item.group_id]);
    } else if (item.type === "event") {
      this.router.navigate(["/event-list"]);
    }
  }

  toggle() {
    this.show = !this.show;
  }

  toggleSearch() {
    this.isSearch = !this.isSearch;
  }
  toggleNotification(event: any) {
    event.stopPropagation();
    if (this.length > 0) {
      // this.api.post('notification/read').subscribe((res: any) =>{
      //   if (res.status === 200) {
      //     this.length = 0
      //   }
      // }, err =>{
      //   this.api.alert('Something went wrong', 'error');
      // });
    }
    this.show1 = !this.show1;
  }
  onClick() {
    this.show1 = false;
  }
  togglebtn(): any {
    document.body.classList.toggle("body-active");
  }

  ngOnInit(): void {
    this.event.isUserUpdate.subscribe((resp: any) => {
      if (resp) {
        this.getProfile();
      }
    });
  }

  getProfile() {
    this.api.get("user").subscribe((res: any) => {
      if (res.status) {
        this.profile = res.data;
        console.log(this.profile);
      }
    });
  }

  userSearch() {
    if (this.user) {
      console.log(this.user);
      // this.api.get(`search-by-name/${this.user}`).subscribe((res: any) =>{
      //   if (res.status) {
      //     this.result = res.data;
      //     console.log('result---->', this.result);
      //   }
      // })
      localStorage.setItem("search", this.user);
      this.router.navigate(["/search-parent"]);
    }
    this.isSearch = false;
    this.user = null;
  }

  logout() {
    this.api.post("logout").subscribe(
      (res: any) => {
        if (res.status) {
          this.api.alert(res.message, "success");
          this.storage.clearUser();
          this.USER = null;
          this.event.setLoginEmmit(false);
          this.router.navigate(["/home"]);
        } else {
          this.api.alert(res.message, "warning");
        }
      },
      (err) => {
        this.api.alert("Something went wrong", "error");
      }
    );
  }

  callNavigation() {
    console.log("works");
  }
  delacct(){
    this.api.alertModal('Are you sure ?','warning',true,true).then((res:any)=>{
      if(res.isConfirmed==true){
        this.api.get("delete-user").subscribe(
          (res: any) => {
            if (res.status) {
              this.api.alert(res.message, "success");
              this.storage.clearUser();
              this.USER = null;
              this.event.setLoginEmmit(false);
              this.router.navigate(["/home"]);
            } else {
              this.api.alert(res.message, "warning");
            }
          },
          (err) => {
            this.api.alert("Something went wrong", "error");
          }
        );
      }
      
    })
  }
}
