<header class="header-wrapper">
  <div class="container" *ngIf="!USER">
    <div class="row">
      <div class="col-12 header-holder">
        <button class="hamberger-btn" type="button" (click)="toogleBtn()">
          <i class="fa fa-bars"></i>
        </button>
        <div class="logo-holder">
          <a routerLink="/"><img src="./assets/images/logo.png" alt="Logo"></a>
        </div>
        <ul class="navigation">
          <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
          <li><a routerLink="/about" routerLinkActive="active">About Us</a></li>
          <!-- <li><a routerLink="/how-it-works">How It Works</a></li> -->
          <li><a routerLink="/how-we-are-different" routerLinkActive="active">How We Are Different</a></li>
          <!-- <li><a routerLink="/subscribe">Subscribe</a></li> -->
          <!-- <li><a>Faq</a></li>
          <li><a>Contact Us</a></li> -->
        </ul>
        <div class="account-holder">
          <a *ngIf="!USER" routerLink="/signup" style="margin-right: 10px;" class="btn">Sign Up <i class="fa fa-sign-in"></i></a>
          <a *ngIf="!USER" routerLink="/login" class="btn">Sign In <i class="fa fa-sign-in"></i></a>
          <a *ngIf="USER" (click)="logout()" class="btn">Logout <i class="fa fa-sign-in"></i></a>
        </div>
      </div>
    </div>
  </div>

  <!-- AFTER LOGIN -->

  <div class="container" *ngIf="USER">
    <div class="afterLogin">
      <div class="col-12 header-holder" style="min-height: 60px;">
        <button class="hamberger-btn" type="button" (click)="toogleBtn()">
          <i class="fa fa-bars"></i>
        </button>
        <div class="logo-holder">
          <a routerLink="/"><img src="./assets/images/logo.png" alt="Logo"></a>
        </div>
        <ul class="navigation">
          <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
          <li><a routerLink="/about" routerLinkActive="active">About Us</a></li>
          <!-- <li><a routerLink="/how-it-works">How It Works</a></li> -->
          <li><a routerLink="/how-we-are-different" routerLinkActive="active">How We Are Different</a></li>
          <!-- <li><a routerLink="/subscribe">Subscribe</a></li> -->
          <!-- <li><a >Faq</a></li>
        <li><a >Contact Us</a></li> -->
        </ul>
        <div class="account-holder">
          <!-- <a *ngIf="USER" (click)="logout()" class="btn">Logout <i class="fa fa-sign-in"></i></a> -->


          <div class="headerWrapper">



            <!-- <div class="col-sm-auto searchbox" *ngIf="isShow">
              <button #toggleButton *ngIf="!isSearch" (click)="toggleSearch()" style="background: transparent;"><img
                  src="assets/images/searchic.svg" alt=""></button>
              <div class="searchWrap" *ngIf="isSearch">
                <input type="Search" [(ngModel)]="user" (keyup.enter)="userSearch()" class="form-control"
                  placeholder="Search">
                <div class="btnBox d-flex align-items-center px-2">
                  <button (click)="userSearch()"><img src="assets/images/searchic.svg" alt=""></button>
                </div>
              </div>
            </div> -->

            <div class="header-notification">
              <button type="button" class="notibtn" (click)="toggleNotification($event)">
                <mat-icon>notifications</mat-icon>
                <span class="noti-count" *ngIf="length > 0">{{length}}</span>
              </button>
              <div class="notifications" *ngIf="show1">
                <h2>Notifications <span *ngIf="length > 0">{{length}}</span></h2>
                <div class="notiItems">
                  <ul>
                    <!-- <li *ngFor="let item of notificationList">
                  <h3><span class="circle red"></span> John Doe Invites You For Join XYZ Group <span class="dateTime">30
                      April 2021 <em>|</em> 10:45 AM</span></h3>
                      <h3><span class="circle red"></span> {{item?.body}} <span class="dateTime">{{item?.date_time | date:'dd/MM/yyyy'}} <em>|</em> {{item?.date_time | date:'h:mm a'}}</span></h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                    Industry.Lorem Ipsum is simply dummy text of the .</p>
                </li> -->
                    <ng-container *ngIf="notificationList?.length >0">
                      <li [ngClass]="item?.is_read === 0 ? 'unread' : ''" *ngFor="let item of notificationList" style="cursor: pointer;"
                        (click)="markAsRead(item)">
                        <h3><span *ngIf="item?.is_read === 0" class="circle red"></span> {{item?.body}} <span
                            class="dateTime">{{item?.created_at | date:'dd/MM/yyyy'}} <em>|</em>
                            {{item?.created_at | date: 'h:mm a'}}</span></h3>

                      </li>
                    </ng-container>
                    <ng-container *ngIf="notificationList?.length === 0">
                      No records found
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="nameImg">
              <span class="profile-name">Hi, {{profile?.full_name}}!</span>
              <figure>
                <img class="profile-image" src="{{imgUrl}}profile_picture/{{profile?.profile_photo_path}}" alt=""
                  [matMenuTriggerFor]="menu" onError="this.src='assets/images/no_image.jpg';">
                <span class="active-status"></span>
              </figure>
            </div>
          </div>
          <mat-menu #menu="matMenu" class="afterLoginMenu">

            <button mat-menu-item routerLink="/edit-profile" routerLinkActive="active">
              <span class="material-icons">
                person
              </span>
              Edit Profile</button>
            <button *ngIf="profile?.role_name === 'PARENT'" mat-menu-item routerLink="/search-group"
              routerLinkActive="active">
              <span class="material-icons">
                groups
              </span>
              Search Group</button>
            <button mat-menu-item routerLink="/change-password" routerLinkActive="active">
              <span class="material-icons">
                password
              </span>
              Change Password</button>
              <button mat-menu-item (click)="delacct()">
                <span class="material-icons">
                  delete_outline
                </span>Delete Account</button>
            <button mat-menu-item (click)="logout()">
              <span class="material-icons">
                logout
              </span>Logout</button>

          </mat-menu>
        </div>
      </div>



    </div>
  </div>

</header>