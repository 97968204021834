<div class="paymentMethod">
    <div *ngIf="!isAdd">
        <h2>Payment Methods</h2>
        <button type="button" (click)="add()" class="btn1">
            <mat-icon>add</mat-icon>Add Card
        </button>
        <ul class="cardswrapper">
            <ng-container *ngIf="cardList?.length > 0">
            <li *ngFor="let item of cardList">
                <div class="cardd">
                    <mat-radio-group (change)="onRadioChange($event)">
                        <mat-radio-button value="{{item?.id}}" [checked]="item?.isChecked"><img
                                *ngIf="item?.card_brand === 'visa'" src="./assets/images/visa.png" alt="">
                            <img *ngIf="item?.card_brand === 'mastercard'" src="./assets/images/master.png" alt="">
                            <img *ngIf="item?.card_brand !== 'visa' && item?.card_brand !== 'mastercard'"
                                src="./assets/images/group.png" alt="">**** **** **** {{item?.last4}}
                        </mat-radio-button>

                    </mat-radio-group>
                    <button type="button" class="delete-btn" *ngIf="item?.is_default === 0"
                        (click)="delete(item?.id, item?.is_default)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="cardList?.length === 0">
            No card present
        </ng-container>
        </ul>
        <div class="text-center login-signup-input" *ngIf="data?.type === 'subscription'">
            <button type="submit" *ngIf="cardList?.length > 0" class="btn1 text-uppercase" (click)="purchase()">Proceed To Payment</button>
        </div>
    </div>
    <ng-container *ngIf="isAdd">
        <h2>Add Card</h2>
        <form [formGroup]="purchaseForm">
            <!-- <div class="login-signup-input">
            <input type="text" placeholder="Card Type" formControlName="type">
            <div class="error"
                *ngIf="purchaseForm.controls.type.touched && purchaseForm.controls.type.hasError('required')">
                Please enter the card type
            </div>
        </div> -->
            <!-- <div class="login-signup-input">
                <input type="text" placeholder="Card Name" formControlName="name">
                <div class="error"
                    *ngIf="purchaseForm.controls.name.touched && purchaseForm.controls.name.hasError('required')">
                    Card Holder Name is required
                </div>
            </div> -->
            <div class="login-signup-input">
                <input type="text" placeholder="Card Number" mask="0*" formControlName="cardNo">
                <div class="error"
                    *ngIf="purchaseForm.controls.cardNo.touched && purchaseForm.controls.cardNo.hasError('required')">
                    Card Number is required
                </div>
                <div class="error"
                *ngIf="purchaseForm.controls.cardNo.touched && purchaseForm.controls.cardNo.hasError('minlength')">
                Card Number must have minimum 15 digits
                </div>
            </div>
            <div class="login-signup-input">
                <input type="text" placeholder="Expiration Month(MM)" mask="00" formControlName="expMn">
                <div class="error"
                    *ngIf="purchaseForm.controls.expMn.touched && purchaseForm.controls.expMn.hasError('required')">
                    Expiry Month is required
                </div>
                <div class="error"
                *ngIf="purchaseForm.controls.expMn.touched && purchaseForm.controls.expMn.hasError('minlength')">
                Expiry Month must be minimum 2 digits
            </div>
            </div>
            <div class="login-signup-input">
                <input type="text" placeholder="Expiration Year(YYYY)" mask="0000" formControlName="expYr">
                <div class="error"
                    *ngIf="purchaseForm.controls.expYr.touched && purchaseForm.controls.expYr.hasError('required')">
                    Expiry Year is required
                </div>
                <div class="error"
                    *ngIf="purchaseForm.controls.expYr.touched && purchaseForm.controls.expYr.hasError('minlength')">
                    Expiry Year must be of minimum length 4
                </div>
            </div>
            <div class="login-signup-input">
                <input type="password" placeholder="CVC" formControlName="cvv">
                <div class="error"
                    *ngIf="purchaseForm.controls.cvv.touched && purchaseForm.controls.cvv.hasError('required')">
                    CVC is required
                </div>
            </div>
            <div class="text-center login-signup-input">
                <button type="submit" class="btn1 text-uppercase" (click)="proceed()">Add Card</button>
            </div>
        </form>
    </ng-container>
</div>