<div class="createPostModal">
    <form [formGroup]="createPostForm">
        <div class="row">
            <div class="col-lg-12">

                <div class="login-signup-input" (click)="galleryImage.click()">
                    <label for="file-type" class="profileimg">
                        <!-- <img src="./assets/images/image-gallery.png" alt=""> -->
                        <figure> <img [src]="imagestoupload ?  imagestoupload : './assets/images/image-gallery.png'"
                                alt="">
                        </figure>
                        <span>Upload Post Picture</span>
                    </label>
                    <div class="fileUpload">
                        <input type="file" #galleryImage (change)="onImageUpload($event)" accept=".png, .jpg, .jpeg"
                            id="file-type"> <button type="button" class="btn1">Upload</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="login-signup-input">
                    <label>Title</label>
                    <input type="text" formControlName="title" placeholder="Enter Title">
                    <div class="error"
                        *ngIf="createPostForm.controls.title.touched && createPostForm.controls.title.hasError('required')">
                        Title is required
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="login-signup-input">
                    <label>Description</label>
                    <textarea placeholder="Description" formControlName="description"
                        aria-placeholder="Enter Description..."></textarea>
                    <div class="error"
                        *ngIf="createPostForm.controls.description.touched && createPostForm.controls.description.hasError('required')">
                        Description is required
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="text-center login-signup-input">
                    <button type="submit" class="btn1 text-uppercase" (click)="create()">Create
                        Post</button>

                </div>
            </div>
        </div>
    </form>
</div>