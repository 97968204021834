import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private storage: StorageService
  ) { }

  loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  login = new BehaviorSubject(this.storage.getUser());
  isLogin = this.login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  private userUpdate = new BehaviorSubject(false);
  isUserUpdate = this.userUpdate.asObservable();

  isRouterChanged = new BehaviorSubject(false);
  isRouterUpdate = this.isRouterChanged.asObservable();
  
  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }

  setLoginEmmit(isLogin: boolean) {
    return this.login.next(isLogin);
  }

  setUserUpdateEmmit(isUserUpdate: boolean) {
    return this.userUpdate.next(isUserUpdate);
  }

  setRouterUpdate(isRouterUpdate: boolean) {
    return this.isRouterChanged.next(isRouterUpdate)
  }
}
