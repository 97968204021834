import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';
import { HttpClientModule } from '@angular/common/http';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {
  createPostForm: FormGroup;
  imagestoupload: any;

  groupId: any;
  isSelected: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatePostComponent>,
    private api: ApiService, private router: Router,
    private route: ActivatedRoute,
    private http: HttpClientModule
  ) { }

  ngOnInit(): void {
    this.formInit();
    console.log(this.data);
  }
  formInit() {
    this.createPostForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),

      image: new FormControl('')

    })
  }

  onImageUpload(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      this.isSelected = true;
      let ftu;
      ftu = event.target.files[0];
      this.createPostForm.patchValue({ image: ftu });
      console.log(this.createPostForm.controls.image.value);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagestoupload = e.target.result;
        console.log(this.imagestoupload);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  create() {
    console.log(this.createPostForm);
    if (this.createPostForm.valid) {
      let obj = new FormData();
      obj.set('group_id', this.data);
      obj.set('title', this.createPostForm.controls.title.value);
      obj.set('description', this.createPostForm.controls.description.value);

      obj.set('image', this.createPostForm.controls.image.value);

      this.api.postMultiData('posts', obj).subscribe((res: any) => {
        if (res.status) {
          this.api.alert(res.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.api.alert(res.message, 'warning');
        }
      }, err => {
        this.api.alert('Something went wrong', 'error');
      });
    } else {
      this.createPostForm.markAllAsTouched();
    }
  }

}
