import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default/default.component';
import { PaymentComponent } from './payment/payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '@project/material.module';
import { CreatePostComponent } from './create-post/create-post.component';
import { CommentComponent } from './comment/comment.component';
import { InviteUserComponent } from './invite-user/invite-user.component';



@NgModule({
  
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskModule
  ],
  declarations: [DefaultComponent, PaymentComponent, CreatePostComponent, CommentComponent, InviteUserComponent],
   entryComponents: [DefaultComponent],
})
export class ModalModule { }
