import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
USER: any;
  isEdit: any;
  isShow: boolean;
  comments: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentComponent>,
    private api: ApiService, private router: Router,
    private storage: StorageService
  ) {
    this.getProfile();
   }

  ngOnInit(): void {
    console.log(this.data);
  }
  getProfile() {
    this.api.get('user').subscribe((res: any) =>{
      if (res.status) {
        this.USER = res.data;
      }
    })
  }
  edit(i: any) {

    this.isEdit = i;
    // this.data[i].isHide = true;
    this.isShow = true;
    this.comments = this.data[i].comment;
  }
  submit(item:any) {
    let data={
      comment: this.comments,
      id: item.id,
      post_id: item.post_id,
      addOrEdit: 1
    };
    console.log('dsdsd--->',data);
    this.dialogRef.close(data);
  }
}
