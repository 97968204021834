import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {
  groups: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<InviteUserComponent>,
  private api: ApiService
  ) {
    
   }

  ngOnInit(): void {
   
  console.log('Data--->',this.data);
  
   
  }
  checkUncheck(event: any,item:any) {
    // console.log(event);
    item.event_added = event.checked;
  }

  submit() {
    let reqarr = []
    this.data.forEach(element => {
      if(element.event_added){
        reqarr.push(element);
      }
    });
    // console.log(reqarr);
    this.dialogRef.close(reqarr);
  }

}
