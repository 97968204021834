import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  purchaseForm: FormGroup;
  isAdd: boolean;
  cardList: any;
  default: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentComponent>,
    private fb: FormBuilder,
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    console.log('data--->', this.data);
    // setTimeout(() => {
    //   this.getCardList();
    // }, 2000);
    this.cardList = this.data.data;
    this.cardList.forEach((ele: any) => {
      if (ele.is_default === 1) {
        ele.isChecked = true;
        this.default = ele.id;
      }
    })
    this.isAdd = false;
    this.formInit();

  }

  getCardList() {
    this.api.post('payment-method-list').subscribe((res: any) => {
      if (res.status) {
        this.cardList = res.data;
        console.log('CARD--->', res);
        this.cardList.forEach((ele: any) => {
          if (ele.is_default === 1) {
            ele.isChecked = true;
            this.default = ele.id;
          }
        })
        this.isAdd = false;
      }
    });
  }
  formInit() {
    this.purchaseForm = new FormGroup({
      // type: new FormControl('', [Validators.required]),
      // name: new FormControl(''),
      cardNo: new FormControl('', [Validators.required, Validators.minLength(15)]),
      expMn: new FormControl('', [Validators.required, Validators.minLength(2)]),
      expYr: new FormControl('', [Validators.required, Validators.minLength(4)]),
      cvv: new FormControl('', [Validators.required]),
    });
  }
  proceed() {
    if (this.purchaseForm.valid) {
      if (this.purchaseForm.controls.expYr.value && this.purchaseForm.controls.expMn.value) {
        if (this.checkExpiry(+this.purchaseForm.controls.expMn.value, +this.purchaseForm.controls.expYr.value)) {
          let data = {
            card_name: this.purchaseForm.controls.name.value,
            card_number: this.purchaseForm.controls.cardNo.value,
            card_cvc: this.purchaseForm.controls.cvv.value,
            card_expiry_month: this.purchaseForm.controls.expMn.value,
            card_expiry_year: this.purchaseForm.controls.expYr.value
          };
          console.log('Crad Data-->', data);
          this.api.post('payment-method-added', data).subscribe((res: any) => {
            if (res.status) {
              this.getCardList();
              this.purchaseForm.reset();
            } else {
              this.api.alert(res.message, 'warning');
            }
          }, err => {
            this.api.alert('Something went wrong', 'error');
          });
        } else {
          this.api.alert('Invalid Expiry', 'warning')
        }
      } else {
        this.api.alert('Invalid Expiry', 'warning')
      }

    } else {
      this.purchaseForm.markAllAsTouched();
    }
  }
  add() {
    this.isAdd = true;
  }
  purchase() {
    let cardId = this.default;
    console.log('jhghj--->', cardId);
    this.dialogRef.close(cardId);
  }

  onRadioChange(event: any) {
    let data = {
      pm_id: event.value
    };
    console.log(data);
    this.api.post('default-payment-method', data).subscribe((res: any) => {
      if (res.status) {
        this.api.alert(res.message, 'success');
        this.getCardList();
      } else {
        this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Something went wrong', 'error');
    });
  }

  delete(id: any, type: any) {
    this.api.alertConfirm('Do you want to delete this card').then((resp: any) =>{
      console.log(resp);
      if (resp) {
 let data = {
      pm_id: id,
      is_default: type
    };
    console.log('dfdfd---->', data);
    this.api.post('payment-method-delete', data).subscribe((res: any) => {
      if (res.status) {
        this.api.alert(res.message, 'success');
        this.getCardList();
      } else {
        this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Something went wrong', 'error');
    });
      }
    });
   
  }

  checkExpiry(mn: any, yr: any) {
    if (mn <= 12 && yr < (new Date().getFullYear() + 10)) {
      const currTm = new Date().getTime();
      const valDt = new Date(yr, mn).getTime();
      if (valDt > currTm) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
