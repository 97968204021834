<div class="header-invite-user">
  <h3>Select People</h3>
</div>

<ul class="invite-user-wrapper">
  <li>
    <!-- <mat-checkbox class="mr-4 mb-4" *ngFor="let item of data" [checked]="item?.event_added" value={{item}} (click)="push(item)"
      (onSelect)="onUserSelect($event)" (onDeSelect)="onUserDeSelect($event)">
      {{item?.full_name}}
    </mat-checkbox> -->
    <mat-checkbox class="mr-4 mb-4" *ngFor="let item of data" [checked]="item?.event_added" value={{item}}
      (change)="checkUncheck($event,item)">
      {{item?.full_name}}
    </mat-checkbox>
  </li>
</ul>
<button class="btn btn-success" (click)="submit()">Submit</button>