import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
socialLinks: any;
  constructor(
    private api:ApiService
  ) { }

  ngOnInit(): void {
    this.getSocialLinks();
  }
  getSocialLinks() {
    this.api.get('cms/contact-us').subscribe((res: any) =>{
      if (res.status) {
        console.log('social--->', res.data);
        this.socialLinks = res.data;
      }
    })
  }
}
