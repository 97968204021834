<div class="footer-wrapper">
    <div class="container">
        <div class="row justify-content-center">
            <!-- <div class="col-12 col-md-4">
                <h2>Let's Chats.</h2>
                <h3>We love to listen<br> your <span>ideas.</span></h3>
                <div class="footer-content">
                    <img src="./assets/images/phone.png" alt="">
                    <a href="tel:0000000025">+ 91 0000000025</a>
                </div>
                <div class="footer-content">
                    <img src="./assets/images/email.png" alt="">
                    <a href="mailto:Vincentmw@protonmail.com">Vincentmw@protonmail.com</a>
                </div>
            </div> -->
            <!-- <div class="col-12 col-md-8">
                <h2>Meet our team</h2>
                <p>Our Location</p>pwd
                <div class="footer-content">
                    <img src="./assets/images/map-pin.png" alt="">
                    <span>At vero eos et accusamus et<br>725625, Odio dignissimos.</span>
                </div>
            </div> -->
            <div class="col-12 col-md-4">
                <h2>Quick Links</h2>
                <ul class="footer-links">
                    <li><a routerLink="/home">Home </a></li>
                    <li><a routerLink="/about">About Us</a></li>
                    <li><a routerLink="/how-it-works">How It Works</a></li>
                    <li><a routerLink="/how-we-are-different">How we are different</a></li>
                    <li><a routerLink="/subscribe">Subscribe</a></li>
                    <li><a routerLink="/partner-with-us">Partner With US</a></li>
                    <li><a routerLink="/terms-and-conditions">Terms and Conditions</a></li>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <!-- <li><a routerLink="">Faq</a></li>
                    <li><a routerLink="">Contact Us</a></li>
                    <li><a routerLink="">Payment Info</a></li>
                    <li><a routerLink="">Disclaimer</a></li>
                    <li><a routerLink="/partner-with-us">Partner With US</a></li>
                    <li><a routerLink="/support-desk">Support Desk</a></li>
                    <li><a routerLink="/founders-story">Founders Story</a></li> -->
                </ul>
            </div>
        </div>
        <div class="row copy-right-section">
            <div class="col-12 col-md-6">
                <div class="copiright">
                    <p>&copy; Copyright 2021 kidsfrat.com | All Rights reserved</p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <ul class="social-box">
                    <li><a href="{{socialLinks?.facebook_link}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="{{socialLinks?.twitter_link}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="{{socialLinks?.linkedin_link}}" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>