import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'default';

  constructor(
    private api: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.router.url);
   }

}

