// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // BASE_API_URL: 'https://kidsfrat.dedicateddevelopers.us/api/',
  // IMAGE_BASE_URL: 'https://kidsfrat.dedicateddevelopers.us/upload/',

  BASE_API_URL: 'https://admin.kidsfrat.com/api/',
  IMAGE_BASE_URL: 'https://admin.kidsfrat.com/upload/',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
